import { Routes } from '@angular/router';
import { authGuard } from './guards/auth/auth.guard';
import { provideState } from '@ngrx/store';
import { customerFeatureKey } from './common/stores/customer/customer.selectors';
import { cstReducer } from './common/stores/customer/customer.reducers';
import { provideEffects } from '@ngrx/effects';
import * as customerEffects from './common/stores/customer/customer.effects';
import * as cstWalletListEffect from './common/stores/cst-wallet-list-store/cst-wallet-list.effects';
import { superAdminFeatureKey } from './super-admin/store/super-admin.selectors';
import { superAdminReducer } from './super-admin/store/super-admin.reducers';
import * as superAdminEffects from './super-admin/store/super-admin.effects';
import { accessControlGuard } from './guards/access-control/access-control.guard';
import { cstWalletListKey } from './common/stores/cst-wallet-list-store/cst-wallet-list.selectors';
import { cstWalletListReducer } from './common/stores/cst-wallet-list-store/cst-wallet-list.reducers';
import { myProfileDetailFeatureKey } from './common/stores/my-profile-store/my-profile.selectors';
import { myProfileDetailReducers } from './common/stores/my-profile-store/my-profile.reducers';
import * as myProfileDetailEffect from './common/stores/my-profile-store/my-profile.effects';
import { groupBalanceFeatureKey } from './common/stores/wallet-group-balance/wallet-group-balance.selectors';
import { groupBalanceReducers } from './common/stores/wallet-group-balance/wallet-group-balance.reducers';
import * as groupBalanceEffect from './common/stores/wallet-group-balance/wallet-group-balance.effects';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    loadComponent: () =>
      import('./common/auth-feature/auth-wrapper/auth-wrapper.component').then(
        (comp) => comp.AuthWrapperComponent
      ),
    loadChildren: () =>
      import('./common/auth-feature/auth.routes').then((m) => m.authRoutes),
  },
  {
    path: 'sa',
    data: { routeName: 'sa' },
    canMatch: [authGuard, accessControlGuard],
    providers: [
      provideState(superAdminFeatureKey, superAdminReducer),
      provideEffects(superAdminEffects),
      provideState(myProfileDetailFeatureKey, myProfileDetailReducers),
      provideEffects(myProfileDetailEffect),
      provideState(groupBalanceFeatureKey, groupBalanceReducers),
      provideEffects(groupBalanceEffect),
    ],
    loadComponent: () =>
      import(
        './common/main-content-wrapper/main-content-wrapper.component'
      ).then((comp) => comp.MainContentWrapperComponent),
    loadChildren: () =>
      import('./super-admin/super-admin.routes').then(
        (c) => c.superAdminRoutes
      ),
  },
  {
    path: 'rs',
    data: { routeName: 'rs' },
    canMatch: [authGuard, accessControlGuard],
    providers: [
      provideState(customerFeatureKey, cstReducer),
      provideEffects(customerEffects),
      provideState(myProfileDetailFeatureKey, myProfileDetailReducers),
      provideEffects(myProfileDetailEffect),
      provideState(groupBalanceFeatureKey, groupBalanceReducers),
      provideEffects(groupBalanceEffect),
    ],
    loadComponent: () =>
      import(
        './common/main-content-wrapper/main-content-wrapper.component'
      ).then((comp) => comp.MainContentWrapperComponent),
    loadChildren: () =>
      import('./reseller/reseller.routes').then((c) => c.resellerRoutes),
  },
  {
    path: 'customer',
    data: { routeName: 'customer' },
    canMatch: [authGuard, accessControlGuard],
    providers: [
      provideState(customerFeatureKey, cstReducer),
      provideEffects(customerEffects),
      provideState(cstWalletListKey, cstWalletListReducer),
      provideEffects(cstWalletListEffect),
      provideState(myProfileDetailFeatureKey, myProfileDetailReducers),
      provideEffects(myProfileDetailEffect),
    ],
    loadComponent: () =>
      import(
        './common/main-content-wrapper/main-content-wrapper.component'
      ).then((comp) => comp.MainContentWrapperComponent),
    loadChildren: () =>
      import('./customer/customer.routes').then((c) => c.customerRoutes),
  },
  {
    path: 'select-wallet',
    data: { routeName: 'selectWallet' },
    canMatch: [authGuard, accessControlGuard],
    providers: [
      provideState(cstWalletListKey, cstWalletListReducer),
      provideEffects(cstWalletListEffect),
      provideState(myProfileDetailFeatureKey, myProfileDetailReducers),
      provideEffects(myProfileDetailEffect),
    ],
    loadComponent: () =>
      import(
        './customer/cst-wallet-selection/cst-wallet-selection.component'
      ).then((comp) => comp.CstWalletSelectionComponent),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./common/page-not-found/page-not-found.component').then(
        (comp) => comp.PageNotFoundComponent
      ),
  },
];
